import React, { Component } from 'react';
import { th } from 'date-fns/locale';
import DataTable from 'react-data-table-component';
import {APIURL} from "../../config/config";
import axios from "axios";
import {DateUtil} from "../../utils/dateUtil";
import {NumberUtil} from "../../utils/number-util";
import Loading from "../Loading";

const port = Number(window.env.PORT);

const customStyles = {
    rows: {
        style: {
            minHeight: '35px', // override the row height
        },
    },
    headCells: {
        style: {
            fontWeight: 'bold',
            paddingLeft: '0px', // override the cell padding for head cells
            paddingRight: '0px',
        }
    },
    cells: {
        style: {
            fontSize: 12,
            paddingLeft: '0px', // override the cell padding for head cells
            paddingRight: '0px',
        }
    }
}

class PriceList extends Component{
    constructor (props) {
        super(props);
        this.state = {
            isLoading: false,
            cars: [],
            car_brand_names: [],
            columns: [
                {
                    name: 'No.',
                    selector: row => row.no,
                    sortable: true,
                    center: true,
                    width:'38px'
                },
                {
                    name: 'ยี่ห้อ',
                    selector: row => row.car_brand_name,
                    sortable: true,
                    width:'72px'
                },
                {
                    name: 'รุ่น/รุ่นย่อย',
                    selector: row => row.car_model,
                    sortable: true,
                    width:'280px'
                },
                {
                    name: 'ปี',
                    selector: row => row.car_year_name,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'สี',
                    selector: row => row.car_color_name,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'เกียร์',
                    selector: row => row.car_gear_name,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'ทะเบียนเก่า',
                    selector: row => row.license_plate_old,
                    sortable: true,
                    width:'120px'
                },
                {
                    name: 'ทะเบียนใหม่',
                    selector: row => row.license_plate_new,
                    sortable: true,
                    width:'120px'
                },
                {
                    name: 'ระยะ',
                    selector: row => row.car_date_in_month,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'ซื้อเข้า',
                    selector: row => row.car_date_in_text,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'VAT',
                    selector: row => row.is_car_vat,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'ราคา',
                    selector: row => row.car_sale_price,
                    sortable: true,
                    width:'80px'
                },
                {
                    name: 'Key',
                    selector: row => row.car_spare_key,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'BS',
                    selector: row => row.car_book_service,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'Mile',
                    selector: row => row.car_miles,
                    sortable: true,
                    width:'100px'
                },
            ],
            branchs: [],
            branch_id: '',
            branch_name: '',
            carBrandNameSorted: []
        }

        this.loadCarData = this.loadCarData.bind(this);
        this.loadBranchData = this.loadBranchData.bind(this);
    }

    componentDidMount () {
        this.loadBranchData();
    }

    loadBranchData () {
            // let username = this.state.user.user
            // const is_account_branch = this.state.is_account_branch
            let url = APIURL + '/branch/'
            // if (is_account_branch === 1 ) {
            //     url = APIURL + '/branch/user/'+username
            // }
        // branch_id, branch_name

            axios.get(url)
                .then(res => {
                    let branchs = res.data
                    let index = port === 9043 ? branchs.length - 1 : 0
                    let branch = branchs[index]
                    this.setState({
                        branchs: branchs,
                        branch_id: branch.branch_id,
                        branch_name: branch.branch_name,
                    }, () => {
                        this.loadCarData()
                    });
                }).catch(error => {
                this.setState({ branch: [] })
            })

    }

    loadCarData() {
        let branch_id = this.state.branch_id

        this.setState({
            isLoading: true
        },() => {

            let data = {
                branch_id: branch_id,
                car_status_id: '',
                car_structure_id: '',
                car_gear_id: '',
                car_brand_id: '',
                order_by: '',
                car_color_id: '',
                search_text: '',
                is_star: ''
            }

            let uri = APIURL + '/car/criteria';
            // let uri = APIURL + '/car'
            axios.post(uri, data).then(res => {
                let data = res.data
                // console.log(data)
                // console.log(data[0])
                let _ = require('lodash');
                let carBrandNameUniq = _.uniqBy(data, 'car_brand_name');
                let carBrandNameSorted = _.orderBy(carBrandNameUniq, ['car_brand_name'], ['asc'])

                let cars = []
                for(let i=0; i < carBrandNameSorted.length  ;i++) {
                    let car_sort = carBrandNameSorted[i]
                    let car_brand_name = car_sort.car_brand_name
                    let carByBrands = data.filter(d => d.car_brand_name === car_brand_name)
                    let carByBrandSum = carByBrands.length

                    let car = {
                        car_id: 'h'+i,
                        no: '',
                        car_brand_name: car_brand_name,
                        car_model: carByBrandSum,
                        car_year_name: '',
                        car_color_name: '',
                        car_gear_name: '',
                        license_plate_old: '',
                        license_plate_new: '',
                        car_date_in_month: '',
                        car_date_in_text: '',
                        is_car_vat: '',
                        car_sale_price: '',
                        car_spare_key: '',
                        car_book_service: '',
                        car_miles: '',
                        content_type: 'head'
                    }
                    cars.push(car)

                    for (let j=0; j < carByBrands.length ;j++) {
                        let carByBrand = carByBrands[j]
                        let no = j+1
                        let car_id = carByBrand.car_id
                        let brand_name = carByBrand.car_brand_name
                        let car_model_name = carByBrand.car_model_name
                        let car_sub_model_name = carByBrand.car_sub_model_name
                        let car_year_name = carByBrand.car_year_name
                        let car_color_name = carByBrand.car_color_name
                        let car_gear_name = carByBrand.car_gear_id === 'a' ? 'AT':'MT'
                        let car_license_plate_old = carByBrand.car_license_plate_old
                        let province_name_old = carByBrand.province_name_old

                        let car_license_plate_new = carByBrand.car_license_plate_new
                        let province_name_new = carByBrand.province_name_new

                        let license_plate_old = car_license_plate_old+' '+province_name_old
                        let license_plate_new = car_license_plate_new+' '+province_name_new
                        let car_date_in = carByBrand.car_date_in
                        let car_date_in_month = car_date_in !== null && car_date_in !== '0000-00-00' ? DateUtil.carDateInMonth(car_date_in) : '-'
                        let car_date_in_text = DateUtil.convertShortMonthYearThai(car_date_in)

                        let is_car_vat = carByBrand.person_vat_id === 2 ? 'YES' : 'NO'
                        let car_sale_price = NumberUtil.addCommasZeroInt((carByBrand.car_sale_price/1000))

                        let car_mile_comma = NumberUtil.addCommasZeroInt(carByBrand.car_miles) //ระยะทางการใช้งาน
                        let car_miles = NumberUtil.replaceCarMiles(car_mile_comma)

                        let car_model = car_model_name+'/'+car_sub_model_name
                        let car_spare_key = carByBrand.car_spare_key
                        let car_book_service = carByBrand.car_book_service

                        let car = {
                            car_id: car_id,
                            no: no,
                            car_brand_name: brand_name,
                            car_model: car_model,
                            car_year_name: car_year_name,
                            car_color_name: car_color_name,
                            car_gear_name: car_gear_name,
                            license_plate_old,
                            license_plate_new,
                            car_date_in_month,
                            car_date_in_text,
                            is_car_vat,
                            car_sale_price,
                            car_spare_key,
                            car_book_service,
                            car_miles,
                            content_type: 'detail'
                        }
                        cars.push(car)
                    }
                }

                // console.log(cars)
                this.setState({
                    cars: cars,
                    carBrandNameSorted: carBrandNameSorted,
                    car_brand_names: carBrandNameUniq,
                    isLoading: false
                })
            })
        })
    }

    getDataCarSearchWithCriteria() {
        let branch_id = this.state.branch_id


    }

    render() {

        const {
            columns,
            cars,
            carBrandNameSorted
        } = this.state

        const conditionalRowStyles = [
            {
                when: row => row.content_type === 'head',
                style: {
                    backgroundColor: 'orange',
                    color: 'white',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            },
            // You can also pass a callback to style for additional customization
            // {
            //     when: row => row.calories < 400,
            //     style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
            // },
        ];

        let branch_list = this.state.branchs.map((branch,index)=>{
            return(
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        })

        let car_count = cars.length - carBrandNameSorted.length

        return (
            <div className="row">

                <Loading isLoading={this.state.isLoading} />

                <div className="col-sm-12 col-md-12">
                    <div className="card card-accent-primary">
                        <div className="card-header  form-inline">

                            <select className={"form-control ml-2 mr-1 mb-1" }
                                    name="branch_id"
                                    style={{width: 180}}
                                    value={this.state.branch_id}
                                    onChange={(e) => {
                                        let branchs= this.state.branchs
                                        let branch_id = e.target.value
                                        console.log(typeof branch_id, branch_id)
                                        let branch = branchs.filter(b => b.branch_id === Number(branch_id))[0]

                                        this.setState({branch_id: branch_id, branch_name: branch.branch_name}, () =>{
                                            this.loadCarData()
                                        })
                                    }}
                            >
                                {branch_list}
                            </select>
                            &nbsp;&nbsp;&nbsp;
                            <strong>รถคงเหลือ {car_count} คัน</strong>
                        </div>
                    </div>
                    <div className="card-block">
                        <div className="row">
                        {
                            cars.length !== 0 && <DataTable
                                columns={columns}
                                data={cars}
                                keyField={'car_id'}
                                wrap={true}
                                allowOverflow={true}
                                customStyles={customStyles}
                                conditionalRowStyles={conditionalRowStyles}
                                fixedHeader={true}
                                fixedHeaderScrollHeight={'550px'}
                                dense
                            />
                        }
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export default PriceList
